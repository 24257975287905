<template>
	<div>
		<!--		&lt;!&ndash;begin::Content header&ndash;&gt;-->
		<!--		<div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">-->
		<!--			<span class="font-weight-bold font-size-3 text-dark-60">-->
		<!--				Don't have an account yet?-->
		<!--			</span>-->
		<!--			<router-link class="font-weight-bold font-size-3 ml-2" :to="{ name: 'register' }">-->
		<!--				Sign Up!-->
		<!--			</router-link>-->
		<!--		</div>-->
		<!--		&lt;!&ndash;end::Content header&ndash;&gt;-->

		<!--begin::Signin-->
		<div class="login-form login-signin">
			<div class="text-center mb-10">
				<h3 class="font-size-h1">{{ $t('VIEWS.Authentication.Login.signIn') }}</h3>
				<p class="text-muted font-weight-semi-bold">{{ $t('VIEWS.Authentication.Login.signInDescription') }}</p>
			</div>

			<!--begin::Form-->
			<b-form class="form" @submit.stop.prevent="onSubmit">
				<div v-if="!!errors" role="alert" v-bind:class="{ show: errors.length > 0 }" class="alert fade alert-danger">
					<div class="alert-text">
						{{ errors }}
					</div>
				</div>

				<b-form-group>
					<b-form-input
						class="form-control form-control-solid h-auto py-4 px-4"
						v-model="$v.form.email.$model"
						:state="validateState('email')"
						aria-describedby="mail-live-feedback"
						:placeholder="$t('VIEWS.Account.mail')"
						autocomplete="username"
					></b-form-input>

					<b-form-invalid-feedback id="mail-live-feedback">{{ $t('VIEWS.Authentication.Login.emailRequired') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group>
					<b-form-input
						class="form-control form-control-solid h-auto py-4 px-4"
						type="password"
						v-model="$v.form.password.$model"
						:state="validateState('password')"
						aria-describedby="password-live-feedback"
						:placeholder="$t('VIEWS.Account.password')"
						autocomplete="current-password"
					></b-form-input>

					<b-form-invalid-feedback id="password-live-feedback">{{ $t('VIEWS.Authentication.Login.passwordRequired') }}</b-form-invalid-feedback>
				</b-form-group>

				<!--begin::Action-->
				<div class="form-group d-flex flex-wrap justify-content-between align-items-center">
					<!--					<a href="#" class="text-dark-60 text-hover-primary my-3 mr-2" id="kt_login_forgot">{{ $t('VIEWS.Authentication.Login.forgotPassword') }}</a>-->
					<button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3">{{ $t('VIEWS.Authentication.Login.signIn') }}</button>
				</div>
				<!--end::Action-->
			</b-form>
			<!--end::Form-->
		</div>
		<!--end::Signin-->
	</div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
	padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from 'vuex';
import {ERROR, LOGIN, LOGOUT} from '@/core/services/store/auth.module';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';

export default {
	mixins: [validationMixin],
	name: 'login',
	data() {
		return {
			// Remove this dummy login info
			form: {
				email: '',
				password: '',
			},
		};
	},
	validations: {
		form: {
			email: {
				required,
				email,
			},
			password: {
				required,
				minLength: minLength(3),
			},
		},
	},
	methods: {
		validateState(name) {
			const { $dirty, $error } = this.$v.form[name];
			return $dirty ? !$error : null;
		},
		resetForm() {
			this.form = {
				email: null,
				password: null,
			};

			this.$nextTick(() => {
				this.$v.$reset();
			});
		},
		onSubmit() {
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				return;
			}

			const email = this.$v.form.email.$model;
			const password = this.$v.form.password.$model;

			// clear existing errors
			this.$store.dispatch(LOGOUT);

			// set spinner to submit button
			const submitButton = this.$refs['kt_login_signin_submit'];
			submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

			this.$store
				.dispatch(LOGIN, { email, password })
				// go to which page after successfully login
				.then(data => {
					if (typeof data === 'undefined') {
						this.$store.dispatch(ERROR, this.$t('ERROR.G.wrongPassword'));
						this.form.password = '';
					} else {
						if ('redirect' in this.$route.query) {
							this.$router.push(this.$route.query.redirect)
						}
						else {
							this.$router.push({ name: 'dashboard' });
						}
					}
				});
			submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
		},
	},
	computed: {
		...mapState({
			errors: state => state.auth.errors,
		}),
	},
};
</script>
